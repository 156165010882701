import React, { useEffect, useRef, useState } from 'react';
import { Viewer, Cartesian3, Color, Cesium3DTileset, Math, HeadingPitchRange, Matrix4 } from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch"; 
import { setCesiumView } from 'redux/slices/mapbox/mapSlice';
import { selectFlightAction } from 'redux/slices/flight/selected';

const ToolbarStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "4px", 
  right: "200px", 
  zIndex: 1000, 
  backgroundColor: theme.palette.primary.dark, 
  padding: theme.spacing(0.5), 
  borderRadius: "8px", 
}));

const ModeLabel = styled('span')(({ theme, isChecked }) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary, 
  marginRight: isChecked ? '0' : '8px', 
  marginLeft: isChecked ? '8px' : '0',
}));


const CesiumMap = () => {
  const viewerRef = useRef(null);
  const [tilesetData, setTilesetData] = useState(null);
  const dispatch = useDispatch();
  const flights = useSelector((state) => state.flightList.data);
  const selectedFlight = useSelector((state) => state.flightSelected);
  const flightUuId = flights?.find((fid) => fid.id === selectedFlight.id)?.uuid;
  const tilesApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/3d_tileset_file/${flightUuId}/tileset.json`;
  const is3D = useSelector((state) => state.mapState.is3D);
  const [cesium, setCesium] = useState(is3D);
  
  const switchtoCesium = () => {
    setCesium((prevCesium) => {
      const updatedCesium = !prevCesium;
      dispatch(setCesiumView(updatedCesium));
      if(!updatedCesium) {
        dispatch(selectFlightAction({ flight : selectedFlight }));
      }
      return updatedCesium;
    });
  };

  // useEffect(() => {
  //   if (!flightUuId) return;

  //   PrivateRequestBase('get', `api/3d_tileset_file/?flight_uuid=${flightUuId}`)
  //     .then((response) => {
  //       const tileset = response.data?.data?.[0]?.tile_set_json;
  //       setTilesetData(tileset); 
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching tileset:', error);
  //     });
  // }, [flightUuId]);

  useEffect(() => {
    if (flightUuId) {
      const viewer = new Viewer(viewerRef.current, {
        animation: false,
        timeline: false,
      });

      // viewer.entities.add({
      //   name: 'Example Location',
      //   position: Cartesian3.fromDegrees(-74.0707383, 40.7117244, 1500),
      //   point: {
      //     pixelSize: 10,
      //     color: Color.RED,
      //   },
      // });

      const tileset = new Cesium3DTileset({
        url: tilesApiUrl,
      });

      viewer.scene.primitives.add(tileset);

      // tileset.readyPromise
      //   .then(() => {
      //     viewer.zoomTo(tileset);
      //   })
      //   .catch((error) => {
      //     console.error('Failed to load tileset:', error);
      //   });


      tileset.readyPromise
      .then(() => {
        viewer.zoomTo(tileset).then(() => {
          viewer.camera.moveBackward(5000); 
          viewer.camera.moveDown(2000);
          viewer.camera.moveForward(2000);
          viewer.camera.moveUp(1000);
          viewer.camera.moveForward(3000);
          viewer.camera.moveLeft(500);
          // viewer.camera.lookAtTransform(Matrix4.IDENTITY, new HeadingPitchRange(0, Math.toRadians(-30), 5000)); 
        });
      })
      .catch((error) => {
        console.error('Failed to load tileset:', error);
      });


      return () => {
        if (viewer && !viewer.isDestroyed()) {
          viewer.destroy();
        }
      };
    }
  }, [tilesetData]);

  return (
    <>
    <div
      ref={viewerRef}
      style={{ width: '100%', height: '100vh' }}
    />
    
    <ToolbarStyled>
      <ModeLabel isChecked={false}>2D</ModeLabel>
        <Switch
          checked={is3D}
          onChange={switchtoCesium}
          color="primary"
          disabled={!is3D}
        />
    <ModeLabel isChecked={false}>3D</ModeLabel>
        </ToolbarStyled>
    </>
    
  );
};

export default CesiumMap;
