import React, { useEffect, useRef } from 'react';
import Marzipano from 'marzipano';
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { setPanoViewAction } from "redux/slices/mapbox/mapSlice";
import { clearPanoImage } from "redux/slices/Panorama/list";
import CloseIcon from '@mui/icons-material/Close';

const PanoramaComponent = () => {
  const dispatch = useDispatch();
  const viewerRef = useRef(null);
  const panoImage = useSelector((state) => state.pano);

  useEffect(() => {
    const viewerElement = viewerRef.current;
    const viewer = new Marzipano.Viewer(viewerElement);
    const dir_path = panoImage?.tiles_dir_path;
    const faceMap = {
      b: 'b',  
      f: 'f', 
      l: 'l', 
      r: 'r',  
      u: 'u',  
      d: 'd',  
    };
    const panoPath = `https://ctadmin.civiltracker.xyz/api/panorama_image_tiles/?dir_path=${dir_path}&z_value={z}&image_name={f}{y}_{x}.jpg`;
    const source = Marzipano.ImageUrlSource.fromString(panoPath,
      // 'https://ctadmin.civiltracker.xyz/api/pano_static_view_generate/{z}/{f}{y}_{x}.jpg',  
      {
        // cubeMapPreviewUrl: 'output/preview.jpg',  
        faceMap: faceMap, 
      }
    );

    const geometry = new Marzipano.CubeGeometry([
      { tileSize: 512, size: 512 },     
      { tileSize: 1024, size: 1024 },  
      // { tileSize: 2048, size: 2048 },   
      // { tileSize: 4096, size: 4096 },   
    ]);

    const limiter = Marzipano.RectilinearView.limit.traditional(8192, 100 * Math.PI / 180);
    // const limiter = Marzipano.RectilinearView.limit.traditional(16384, 100 * Math.PI / 180);
    // const view = new Marzipano.RectilinearView({ yaw: 0, pitch: 0, fov: Math.PI / 2 }, limiter);
    const view = new Marzipano.RectilinearView(null, limiter);

    const scene = viewer.createScene({
      source: source,
      geometry: geometry,
      view: view,
      webglConstraints: {
        alpha: false,
        depth: false,
        antialias: false,
        preserveDrawingBuffer: false,
        powerPreference: 'high-performance'
      }
      // pinFirstLevel: true
    });

    scene.switchTo();  

    return () => {
      viewer.destroy(); 
    };
  }, []);

  const handlePanoViewClick = () => {
    dispatch(setPanoViewAction(false));
    dispatch(clearPanoImage());
};

return (
  <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
    <IconButton
      onClick={handlePanoViewClick}
      sx={{ position: 'absolute', top: '10px', right: '10px', zIndex: 10 }}
    >
      <CloseIcon sx={{ width: 40, height: 40 }} style={{ color: "gray" }} />
    </IconButton>

    <div ref={viewerRef} style={{ width: '100%', height: '100%' }} />
  </div>
);

}

export default PanoramaComponent;
