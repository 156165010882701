import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import {
  setMapStateAction,
  setDefaultMapViewAction,
} from "../../redux/slices/mapbox/mapSlice";
import "mapbox-gl/dist/mapbox-gl.css";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch"; 
import { retainMapStateAfterRefreshAction } from "../../redux/slices/layers";
import { useHistory } from "react-router-dom";
import { setCesiumView } from "redux/slices/mapbox/mapSlice";

const BoxStyled = styled(Box)(({ theme }) => ({
  height: "calc(100vh - 64px)",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    height: "calc(100vh - 54px)",
  },
}));

const ModeLabel = styled('span')(({ theme, isChecked }) => ({
  fontWeight: 'bold',
  color: theme.palette.secondary, 
  marginRight: isChecked ? '0' : '8px', 
  marginLeft: isChecked ? '8px' : '0',
}));

const ToolbarStyled = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "10px", 
  right: "50px", 
  zIndex: 1000, 
  backgroundColor: theme.palette.primary.dark, 
  padding: theme.spacing(0.5), 
  borderRadius: "8px", 
}));

const MapComponent = ({ selectedProjectId }) => {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const mapState = useSelector((state) => state.mapState);
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(false); 
  const [cesium, setCesium] = useState(false);

  
const switchtoCesium = () => {
  setCesium((prevCesium) => {
    const updatedCesium = !prevCesium;
    dispatch(setCesiumView(updatedCesium));
    if(!updatedCesium) {
      console.log('updatedCesium', !updatedCesium);
      dispatch(selectFlightAction({ flight : selectedFlight }));
    }
    return updatedCesium;
  });
};

  useEffect(() => {
    if (selectedProjectId && map.current) {
      map.current.on("idle", () => {
        const selectedProjectFlights = mapState.projects.find(
          (project) => project.id === selectedProjectId
        )?.flights;

        // Remove markers if no flights are present for the selected project
        if (!selectedProjectFlights || selectedProjectFlights.length === 0) {
          if (map.current && map.current._markers) {
            Object.values(map.current._markers).forEach((marker) => {
              marker.remove();
            });
          }
        }
      });
    }
  }, [mapState, selectedProjectId]);

  const mapCheck = () => {
    const isLoaded = map.current.loaded();
    if (isLoaded) {
      dispatch(retainMapStateAfterRefreshAction({ history: history }));
    } else {
      mapCheck();
    }
  };

  useEffect(() => {
    if (map.current) return;
    const mapData = {
      container: mapContainer.current,
      style: {
        version: 8,
        sources: {
          "raster-tiles": {
            type: "raster",
            url: "mapbox://mapbox.satellite",
            tileSize: 256,
          },
        },
        layers: [
          {
            id: "simple-tiles",
            type: "raster",
            source: "raster-tiles",
            minzoom: 0,
            maxzoom: 22,
          },
        ],
      },
      center: [mapState.lng, mapState.lat],
      zoom: mapState.zoom,
    };
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_ACCESS_TOKEN;
    map.current = new mapboxgl.Map(mapData);
    map.current.addControl(new mapboxgl.NavigationControl());
    window.map = map.current;

    return () => map.current.remove();
  }, []);

  useEffect(() => {
    if (!map.current) return; 
    map.current.on("idle", (idleState) => {
      if (
        map.current.getZoom() !== mapState.zoom ||
        map.current.getCenter().lng !== mapState.lng ||
        map.current.getCenter().lat !== mapState.lat
      ) {
        dispatch(
          setMapStateAction({
            lng: map.current.getCenter().lng,
            lat: map.current.getCenter().lat,
            zoom: map.current.getZoom(),
          })
        );
      }
    });

    map.current.on("remove", () => {
      console.log("A remove event occurred.");
    });

    map.current.on("load", mapCheck);
    map.current.on("render", (event) => {
      event.target.resize();
    });
  }, []);

  return (
    <>
      <BoxStyled ref={mapContainer} />
      <ToolbarStyled>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ModeLabel isChecked={false}>2D</ModeLabel>
          <Switch
            checked={checked}
            onChange={switchtoCesium}
            color="primary"
            disabled={cesium}
          />
        <ModeLabel isChecked={false}>3D</ModeLabel>
        </div>
      </ToolbarStyled>

    </>
  );
};

export default MapComponent;
